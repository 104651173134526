import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import { Button } from '@naf/button';
import { ArrowForward } from '@styled-icons/material/ArrowForward';
import { BilguideSearchBlockType } from '../../../../../types/bilguideSearchBlockType';
import CarSearch from '../../bilguide/carSearch/CarSearch';
import { actions as carFilterActions } from '../../../redux/modules/carFilter';
import { actions as carsActions } from '../../../redux/modules/cars';
import { getURLParams } from '../../../lib/getUrlParams';
import createAPIQuery from '../../../lib/createURLQueries';
import useSelector from '../../../redux/typedHooks';
import { UrlParamsType } from '../../../../../types/urlParamsType';

type BilguideSearchBlockProps = { block: BilguideSearchBlockType };

const fetchElectricCars = (dispatch: Dispatch, searchParams: UrlParamsType = {}) => {
  const queries = createAPIQuery(searchParams, { fuelCategories: ['elektrisk'] });
  dispatch(carsActions.getCars.request(queries));
  dispatch(carFilterActions.setDidRefreshSearch());
};

const BilguideSearchBlock = ({ block }: BilguideSearchBlockProps) => {
  // TODO: Use this once we have some way to filter search on categories from Sanity
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { showCategories } = block;
  const dispatch = useDispatch();

  const cars = useSelector((state) => state.cars);
  const { data: dataIds } = cars;
  const { filterNumber, filterFetchedMap } = useSelector((state) => state.carFilter);
  const hasSingleResult = dataIds?.length === 1 && dataIds[0].length === 1;

  // Update selection when filter selections change in url
  useEffect(() => {
    if (filterNumber !== 1 && filterFetchedMap[filterNumber] !== true) {
      const parsedParams = getURLParams();
      fetchElectricCars(dispatch, parsedParams);
    }
  }, [filterNumber, filterFetchedMap, dispatch]);

  // Update selection when no data is available
  useEffect(() => {
    if (
      typeof dataIds === 'undefined' ||
      dataIds.length === 0 ||
      (dataIds[0].length === 0 && filterFetchedMap[filterNumber] !== true)
    ) {
      const parsedParams = getURLParams();
      fetchElectricCars(dispatch, parsedParams);
    }
  }, [dataIds, dispatch, filterFetchedMap, filterNumber]);

  return (
    <BilguideSearchBlockWrapper>
      <LabelText variant={TextVariant.DataLabel}>Søk etter modell</LabelText>
      <InputWrapper>
        <CarSearch additionalFilters={{ fuelCategories: ['elektrisk'] }} />
        <StyledLink
          to={
            hasSingleResult
              ? `/bilguiden/bilmodell/${dataIds[0][0]}`
              : `/bilguiden/elbiler-i-norge?query=${getURLParams().query}`
          }
        >
          <Button type="button" size="large" variant="secondary" onClick={() => {}}>
            <ButtonContentContainer>
              Søk <ArrowForward width={16} height={16} color={themeLight.typography.brandText} />
            </ButtonContentContainer>
          </Button>
        </StyledLink>
        <StyledLink to="/bilguiden/elbiler-i-norge">
          <Button type="button" size="large" variant="secondary" onClick={() => fetchElectricCars(dispatch)}>
            <ButtonContentContainer>
              Alle modeller <ArrowForward width={16} height={16} color={themeLight.typography.brandText} />
            </ButtonContentContainer>
          </Button>
        </StyledLink>
      </InputWrapper>
    </BilguideSearchBlockWrapper>
  );
};

const LabelText = styled(Text)`
  margin: 0;
`;

const BilguideSearchBlockWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: ${spacing.space8};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: ${spacing.space16} ${spacing.space8};

  /* The actual search field */
  form {
    flex-grow: 1;
    flex-shrink: 3;

    @media (min-width: calc(${breakpoints.m} + 1px)) {
      height: 56px;

      & > div:first-child {
        height: 56px;
      }

      & > div:nth-child(2) {
        top: 64px;
      }
    }
  }

  @media (max-width: ${breakpoints.m}) {
    flex-flow: row wrap;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;

  @media (max-width: ${breakpoints.m}) {
    display: inline-flex;
    flex-basis: 100%;

    button {
      height: 48px;
      flex-grow: 3;
    }
  }
`;

const ButtonContentContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  column-gap: ${spacing.space12};
  align-items: center;
  margin-right: -${spacing.space4};
`;

export { BilguideSearchBlock, BilguideSearchBlockWrapper };
